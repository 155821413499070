













































































































































































































































































































































































































import Vue from "vue";
import { Action } from "vuex-class";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

import {
  Seminar,
  SeminarElement,
  AddElementAction,
  RemoveElementRequest,
} from "@/core/models";
import { typeName } from "@/core/utils/seminars";
import VideoPlayer from "@/components/common/VideoPlayer.vue";
import ConditionsSelect from "@/views/goals/components/ConditionsSelect.vue";
import { NumberSelect } from "@/components/common";

// eslint-disable-next-line prettier/prettier
const ytUrlPattern = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

@Component({
  components: {
    VideoPlayer,
    ConditionsSelect,
    NumberSelect,
  },
})
export default class AdminElementDialog extends Vue {
  @Prop({ default: () => 0 }) blockId!: number;
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Prop({ default: () => false }) editing!: boolean;
  @PropSync("tempElement") element!: SeminarElement | null;
  @PropSync("dialogProp") dialog!: boolean;
  @PropSync("addLoadingProp") addLoading!: boolean;
  @PropSync("editLoadingProp") editLoading!: boolean;
  @PropSync("deleteLoadingProp") deleteLoading!: boolean;

  @Action("seminars/addElement")
  addElement!: (d: AddElementAction) => Promise<void>;
  @Action("seminars/editElement")
  editElement!: (d: AddElementAction) => Promise<void>;
  @Action("seminars/removeElement")
  removeElement!: (d: RemoveElementRequest) => Promise<void>;

  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  typeToString = typeName;

  newVideo: Blob | null = null;
  videoChoice: "none" | "file" | "link" = "none";

  editingCategory = -1;
  newCategoryDialog = false;
  newCategory: { title: string; words: string[] } = { title: "", words: [] };

  get options() {
    return {
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline"],
          ["code-block"],
        ],
      },
      placeholder: this.$t("seminars.edit.elements.desc_text").toString(),
      theme: "snow", // or 'bubble'
      formats: [
        "background",
        "bold",
        "color",
        "font",
        "code",
        "italic",
        "link",
        "size",
        "strike",
        "script",
        "underline",
        "blockquote",
        "header",
        "indent",
        "list",
        "align",
        "direction",
        "code-block",
        "formula",
      ],
    };
  }

  @Watch("tempElement", { immediate: true })
  elChanged() {
    this.dialog = !!this.element;
    if (this.element) this.makeGaps();
  }

  @Watch("dialog", { immediate: true })
  dialogChanged() {
    if (!this.dialog) return;

    // watch video element
    if (this.element?.type === "watch_video")
      if (this.element.id !== 0)
        this.videoChoice = this.element.videoURI.includes("youtu")
          ? "link"
          : "file";
      else this.videoChoice = "none";
  }
  @Watch("videoChoice")
  videoChoiceChanged() {}

  async checkLink(link: string) {
    if (!link.includes("youtu")) return;
    try {
      const match = link.match(ytUrlPattern);
      let code = match && match[7].length == 11 ? match[7] : "";

      if (code === "" && link.includes("v="))
        code = link.split("v=")[1].split("&")[0];
      if (code === "" && link.includes("be/")) code = link.split(".be/")[1];

      const embedLink = "https://youtube.com/embed/" + code;
      (this.element as any).videoURI = embedLink;
    } catch (error) {
      console.log(error);
    }
  }

  // elements
  closeElementDialog() {
    this.element = null;
    this.dialog = false;
  }
  async confirmElementChanges() {
    // check if can be sent
    if (!this.element) return;
    if (!this.element.title)
      return this.displaySnackbar("Must provide a title for each element");

    // add specific stuff to some element

    // watch video
    if (this.element.type === "watch_video") {
      if (!this.element.videoURI && !this.newVideo)
        return this.displaySnackbar(
          this.$t("seminars.edit.elements.watch_video_rule").toString(),
        );
    }
    // categorize
    else if (this.element.type === "categorize")
      this.element.words = this.element.answers.flat();
    // gap text
    else if (this.element.type === "gap_text") {
      this.element.answers = this.gaps;
      if (this.gaps.some(x => !x.trim().length))
        return this.displaySnackbar("Please fill out all gaps");
    }
    // video presentation
    else if (this.element.type === "video_presentation") {
      const reqSub = Number(this.element.requiredSubmissions);
      const maxSub = Number(this.element.maxSubmissions);
      const reqShared = Number(this.element.requiredNumShared);
      const maxShared = Number(this.element.maxNumShared);
      if (isNaN(reqSub) || reqSub < 0 || reqSub > 10 || reqShared > reqSub)
        // invalid reqsub number
        return this.displaySnackbar(
          this.$t("seminars.edit.elements.req_subs_rule_1").toString(),
        );

      if (isNaN(maxSub) || maxSub < 1 || maxSub > 100 || maxSub < reqSub) {
        // invalid maxsub number
        this.displaySnackbar(
          this.$t("seminars.edit.elements.max_shared_rule_3").toString(),
        );
        return;
      }
      if (
        isNaN(reqShared) ||
        reqShared < 0 ||
        reqShared > 10 ||
        reqShared > reqSub
      ) {
        // invalid reqnumshared number
        this.displaySnackbar(
          this.$t("seminars.edit.elements.req_shared_rule_1").toString(),
        );
        return;
      }
      if (
        isNaN(maxShared) ||
        maxShared < 1 ||
        maxShared > 10 ||
        maxShared < reqShared ||
        maxShared > maxSub
      ) {
        // invalid reqnumshared number
        this.displaySnackbar(
          this.$t("seminars.edit.elements.max_shared_rule_4").toString(),
        );
        return;
      }
    }

    this.dialog = false;

    // call action
    this.editing ? (this.editLoading = true) : (this.addLoading = true);
    try {
      const data = { element: this.element, file: this.newVideo };
      if (this.editing) await this.editElement(data);
      else await this.addElement(data);
    } catch (error) {
      console.log(error);
    }
    this.editing ? (this.editLoading = false) : (this.addLoading = false);

    // reset
    this.videoChoice = !this.newVideo ? "link" : "file";
    this.newVideo = null;
    this.element = null;
  }

  removeDialog = false;
  async deleteElement() {
    if (!this.element || this.blockId === 0) return;

    this.dialog = false;
    this.removeDialog = false;
    this.deleteLoading = true;
    try {
      await this.removeElement({ blockId: this.blockId, id: this.element.id });
    } catch (error) {
      console.log(error);
    }
    this.deleteLoading = false;
  }

  // give feedback
  get items() {
    const els: any[] = [];
    this.seminar?.blocks.forEach((b, idx) => {
      els.push({ header: b.title });
      els.push(
        ...b.elements
          .filter(x => x.type === "video_presentation")
          .map(x => ({ name: x.title, value: x.id })),
      );
      if (idx + 1 !== this.seminar?.blocks.length) els.push({ divider: true });
    });
    return els;
  }
  isObject(item: any) {
    return typeof item === "object";
  }

  // gaps in gap text
  gaps: string[] = [];
  makeGaps() {
    if (this.element?.type !== "gap_text") return [];
    const len = this.element.text.split("__").length;
    const diff = len - this.gaps.length - 1;
    const answers = this.element.answers;
    if (diff > 0)
      for (let i = 0; i < diff; i++)
        if (answers[i]) this.gaps.push(answers[i]);
        else this.gaps.push("");
    else if (diff < 0) this.gaps = this.gaps.slice(0, diff);
  }

  // rephrase
  addRephraseRow() {
    if (this.element?.type !== "rephrase") return;
    this.element.sentences.push("");
    this.element.answers.push("");
  }
  removeRephraseRow(i: number) {
    if (this.element?.type !== "rephrase") return;
    this.element.sentences.splice(i, 1);
    this.element.answers.splice(i, 1);
  }

  // categorize
  openAddCategory() {
    if (this.element?.type !== "categorize") return;
    if (this.element.answers.length === 10)
      return this.displaySnackbar("Can't add anymore (10 max)");
    this.newCategory = { title: "", words: [] };
    this.newCategoryDialog = true;
  }
  openEditCategory(i: number) {
    if (this.element?.type !== "categorize") return;
    this.newCategoryDialog = true;
    this.editingCategory = i;
    this.newCategory = {
      title: this.element.categories[i],
      words: [...this.element.answers[i]],
    };
  }
  closeEditCategory() {
    this.newCategoryDialog = false;
    this.editingCategory = -1;
  }
  addCategory() {
    if (this.element?.type !== "categorize") return;
    if (this.editingCategory !== -1) {
      this.element.categories[this.editingCategory] = this.newCategory.title;
      this.element.answers[this.editingCategory] = this.newCategory.words;
      this.editingCategory = -1;
    } else {
      this.element.categories.push(this.newCategory.title);
      this.element.answers.push(this.newCategory.words);
    }
    this.newCategoryDialog = false;
  }
  removeCategory(i: number) {
    if (this.element?.type !== "categorize") return;
    this.newCategory = { title: "", words: [] };
    this.element.categories.splice(i, 1);
    this.element.answers.splice(i, 1);
    this.newCategoryDialog = false;
  }

  // todo has to happen in the Edit.vue
  // queryProcessed = false;
  // @Watch("seminar", { immediate: true })
  // seminarChanged() {
  //   if (this.queryProcessed || !this.seminar) return;
  //   const { block, element } = this.$route.query;
  //   if (block && element) {
  //     const b = this.seminar.blocks.find(x => x.id === Number(block));
  //     if (!b) return;
  //     const el = b.elements.find(x => x.id === Number(element));
  //     if (!el) return;
  //     this.element = { ...el };
  //     this.dialog = true;
  //   }
  //   this.queryProcessed = true;
  // }
}
