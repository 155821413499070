






































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { typeName } from "@/core/utils/seminars";
import {
  Seminar,
  SeminarBlock,
  SeminarElement,
  SeminarOrder,
} from "@/core/models";

import ElementDialog from "./ElementDialog.vue";

@Component({ components: { ElementDialog } })
export default class AdminElement extends Vue {
  @Prop({ default: () => undefined }) element?: SeminarElement;
  @Prop({ default: () => 0 }) blockId!: number;
  @Prop({ default: () => undefined }) seminar?: Seminar;
  @Getter("seminars/blocks") blocks!: SeminarBlock[];
  @Action("seminars/reorder") reorder!: (o: SeminarOrder) => void;

  dialog = false;
  tempElement: SeminarElement | null = null;
  addLoading = false;
  editLoading = false;
  deleteLoading = false;
  get loading() {
    return this.editLoading || this.deleteLoading;
  }
  get typeName() {
    if (!this.element) return "";
    return typeName(this.element.type);
  }
  openEditElement() {
    if (!this.element) return;
    this.dialog = true;
    this.tempElement = JSON.parse(JSON.stringify(this.element));
  }

  moveDialog = false;
  selectedBlock = -2;
  selectedPosition = -2;
  get moveBlocks() {
    return this.blocks.map(x => {
      const b = JSON.parse(JSON.stringify(x)) as SeminarBlock;
      b.elements = b.elements.filter(x => x.id !== this.element?.id);
      return b;
    });
  }
  selectBlock(bi: number) {
    if (this.selectedBlock === bi) this.selectedBlock = -2;
    else this.selectedBlock = bi;
    this.selectedPosition = -2;
  }
  cancelMove() {
    this.moveDialog = false;
    this.selectedBlock = -2;
    this.selectedPosition = -2;
  }
  confirmMove() {
    const order = this.seminar?.order;
    if (!order) return;

    // new order
    const newOrder = JSON.parse(JSON.stringify(order)) as SeminarOrder;
    if (!newOrder) return;

    // find current element position in order
    const newBlockId = this.blocks[this.selectedBlock].id;
    const posIdx = newOrder.elements.findIndex(
      x => x.elementId === this.element?.id,
    );
    if (posIdx === -1) return;

    // create new ElementData object
    const newIndex = this.selectedPosition + 1;
    const newPos = JSON.parse(JSON.stringify(newOrder.elements[posIdx]));
    newPos.elementIndex = newIndex;
    newPos.blockId = newBlockId;

    // Remove from block
    newOrder.elements.splice(posIdx, 1);

    // Push indices back of following elements
    const followingElements = newOrder.elements.filter(
      x => x.blockId === newBlockId && x.elementIndex >= newIndex,
    );
    const otherElements = newOrder.elements.filter(
      x => !followingElements.map(x => x.elementId).includes(x.elementId),
    );
    newOrder.elements = JSON.parse(JSON.stringify(otherElements));
    const pushedBack = followingElements.map(x => {
      const e = { ...x };
      e.elementIndex += 1;
      return e;
    });
    newOrder.elements = [...newOrder.elements, ...pushedBack];

    // Put back in array
    newOrder.elements.push(newPos);

    // fix elements where pos diff > 1
    newOrder.blocks.forEach(b => {
      const elements = newOrder.elements
        .filter(x => x.blockId === b.blockId)
        .sort((a, b) => a.elementIndex - b.elementIndex);
      for (let i = 0; i < elements.length - 1; i++) {
        const curr = elements[i];
        const next = elements[i + 1];
        if (next.elementIndex - curr.elementIndex > 1)
          elements[i + 1].elementIndex = curr.elementIndex + 1;
      }
    });

    // send to api
    this.reorder(newOrder);

    // reset
    this.moveDialog = false;
    this.selectedBlock = -2;
    this.selectedPosition = -2;
  }
}
