




























































import Vue from "vue";
import { Action } from "vuex-class";
import { Component, Prop, PropSync } from "vue-property-decorator";

import { SeminarBlock } from "@/core/models";
import Element from "./Element.vue";
import ElementDialog from "./ElementDialog.vue";

@Component({ components: { Element, ElementDialog } })
export default class AdminBlockDialog extends Vue {
  @PropSync("dialogProp") dialog!: boolean;
  @PropSync("tempBlock") block?: SeminarBlock | null;
  @PropSync("addLoadingProp") addLoading!: boolean;
  @PropSync("editLoadingProp") editLoading!: boolean;
  @PropSync("deleteLoadingProp") deleteLoading!: boolean;
  @Prop({ default: false }) editing!: boolean;

  @Action("seminars/addBlock")
  addBlock!: (b: SeminarBlock) => Promise<void>;
  @Action("seminars/editBlock")
  editBlock!: (b: SeminarBlock) => Promise<void>;
  @Action("seminars/removeBlock")
  removeBlock!: (id: number) => Promise<void>;

  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  // blocks
  closeBlockDialog() {
    this.dialog = false;
  }
  async confirmBlockChanges() {
    if (!this.block) return;
    if (!this.block.title) {
      this.displaySnackbar("Must provide title for each block");
      return;
    }

    this.dialog = false;
    this.editing ? (this.editLoading = true) : (this.addLoading = true);
    try {
      if (this.editing) await this.editBlock(this.block);
      else await this.addBlock(this.block);
    } catch (error) {
      console.log(error);
    }
    this.editing ? (this.editLoading = false) : (this.addLoading = false);
    this.block = null;
  }

  removeDialog = false;
  async deleteBlock() {
    if (!this.block) return;
    this.dialog = false;
    this.removeDialog = false;
    try {
      await this.removeBlock(this.block.id);
      this.block = null;
    } catch (error) {
      console.log(error);
    }
  }
}
