





































































































































































































import Vue from "vue";
import { Component, PropSync } from "vue-property-decorator";
import { ConditionType, Condition } from "@/core/models";

@Component
export default class ConditionsSelect extends Vue {
  @PropSync("conditions") conds!: Condition[];
  dueDateDialog = false;
  get items() {
    return [
      {
        text: this.$t("goals.condtype.date"),
        value: ConditionType.DueDate,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.pitch"),
        value: ConditionType.Pitch,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.ppm"),
        value: ConditionType.Pauses,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.wpm"),
        value: ConditionType.SpeechSpeed,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.overall"),
        value: ConditionType.OverallScore,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.repetition"),
        value: ConditionType.Repetition,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.clarity"),
        value: ConditionType.SpeechClarity,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.filler"),
        value: ConditionType.FillerWords,
        disabled: false,
      },
    ];
  }

  handleTypeChange(i: number) {
    if (this.conds[i].type === ConditionType.SessionScore)
      this.conds[i].value = 50;
    if (this.conds[i].type === ConditionType.Sentiment)
      this.conds[i].value = [20, 70];
    if (this.conds[i].type === ConditionType.Pitch)
      this.conds[i].value = [300, 500];
    if (this.conds[i].type === ConditionType.Volume)
      this.conds[i].value = [10, 50];
    if (this.conds[i].type === ConditionType.Pauses)
      this.conds[i].value = [5, 10];
    if (this.conds[i].type === ConditionType.SpeechSpeed)
      this.conds[i].value = [80, 120];
    if (this.conds[i].type === ConditionType.OverallScore)
      this.conds[i].value = 7;
    if (this.conds[i].type === ConditionType.FillerWords)
      this.conds[i].value = 7;
    if (this.conds[i].type === ConditionType.SpeechClarity)
      this.conds[i].value = 7;
    if (this.conds[i].type === ConditionType.Repetition)
      this.conds[i].value = 7;
    if (this.conds[i].type === ConditionType.DueDate)
      this.conds[i].value = new Date().toISOString().substr(0, 10);

    this.refreshItems();
  }

  refreshItems() {
    this.items.forEach(i => (i.disabled = false));
    let takenTypes = this.conds.map(c => c.type);
    if (takenTypes.length > 0)
      takenTypes.forEach(t => {
        const item = this.items.find(i => i.value === t);
        if (item) item.disabled = true;
      });
  }

  removeCondition(index: number) {
    if (this.conds.length === 1) this.conds = [];
    this.conds.splice(index, 1);
    this.refreshItems();
  }

  addCondition() {
    this.conds.push({ type: "", value: "" });
  }
}
